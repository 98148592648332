import BaseBean from "@/utils/BaseBean";

export interface ISelectTagDataObj {
    utilInst:SelectTagUtil
    refMap:Map<string,any>
    otherParams:any
}

export default class SelectTagUtil extends BaseBean{
    public dataObj:ISelectTagDataObj

    constructor(proxy:any,dataObj:ISelectTagDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}