import {ref,reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, watch,watchEffect} from 'vue';
import SelectTagUtil ,{ISelectTagDataObj} from './selectTagUtil';
export default defineComponent ({
    name: 'selectTag',
    props: {
        name: {type: String,default: ''},//select前面的显示名称
        type: {type: String,default: ''},//类别
        tableName: {type: String,default: ''},//表名
        fieldArr: {type: Array,default: []},//已选字段
        allFields: {type: Array,default: []},//下拉框显示的所有字段
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ISelectTagDataObj=reactive<ISelectTagDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams: {
                fieldArr:[]
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new SelectTagUtil(proxy,dataObj);

        })
        onMounted(()=>{})
        //tmd，watch监听不到第一次数组的变化
        // watch(() => props.fieldArr,async (newValue,oldValue) => {
        //     dataObj.otherParams.fieldArr=newValue;
        // })
        // 创建 watchEffect 来监控 props.fieldArr 的变化
        watchEffect(() => {
            const newFieldArr = props.fieldArr;
            if (newFieldArr !== dataObj.otherParams.fieldArr) {
                dataObj.otherParams.fieldArr = newFieldArr;
            }
        });
        //双击tag修改属性
        const dbHandler=(field:any,type:string,tableName:any)=>{
            let temp=Object.assign({},field);//fuck,必须这样加，如果把field直接传回去，会造成一些恶心的问题出现
            context.emit('dbHandler', {field:temp,type:type,tableName:tableName});
        }
        //关闭/移除tag
        const closeItem=(prop:string,type:string,tableName:any)=>{
            context.emit('closeItemHandler', {prop:prop,type:type,tableName:tableName});
        }
        //下拉select点击删除按钮事件。抛给外面处理吧，否则默认处理的是v-model="fieldArr"，然而fieldArr是只读的，所以不处理会报错
        const removeHandler=(field:any)=>{
            context.emit('closeItemHandler', {prop:field.prop,type:props.type,tableName:props.tableName});
        }
        //下拉select改变事件
        const changeHandler=(newVal:any,oldVal:any)=>{
            context.emit('changeHandler', {newVal:newVal,type:props.type,tableName:props.tableName});
        }
        return{
            ...toRefs(dataObj),dbHandler,closeItem,removeHandler,changeHandler
        }
    }
});